import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';



// ###################################################################### //
// Import WC
import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon } from "wagmi/chains";

// Setup WC
const WC_PROJECT_ID = "dd349967da2dd59a2fa54808652c50ca"
const chains = [arbitrum, mainnet, polygon];
const { provider } = configureChains(chains, [
  walletConnectProvider({ projectId: WC_PROJECT_ID}),
]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: modalConnectors({
    projectId: WC_PROJECT_ID,
    version: "2", 
    appName: "web3Modal",
    chains,
  }),
  provider,
});
const ethereumClient = new EthereumClient(wagmiClient, chains);
// ###################################################################### //



// setup React APP
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <App />
    </WagmiConfig>
    <Web3Modal
        projectId={WC_PROJECT_ID}
        ethereumClient={ethereumClient}
    />
  </React.StrictMode>
);
