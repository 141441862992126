import { Web3Button } from "@web3modal/react";

import { useAccount, useBalance } from 'wagmi'

function App() {

  const { address, isConnected, isConnecting, isDisconnected } = useAccount()
  const { data: balance, isError, isLoading } = useBalance({ address: address });

  console.log(balance)

  return (
    <div className="App">
      <Web3Button />
      
      <h2> Wallet address: {address}</h2>
      <p> Balance: {balance?.formatted} {balance?.symbol}</p>
    </div>
  );
}

export default App;
